import React from "react";

const Services = () => {
  // services details
  const services = [
    {
      name: "Cricket",
      desc: "I love to play hard ball cricket and I'm a top order batsman for one of the Wellington NZ Cricket clubs.",
      icon: "fas fa-baseball-ball",
    },
    {
      name: "Bass Guitarist",
      desc: "I picked up playing bass guitar back in 2010 I still keep playing. It helps me relax my mind.",
      icon: "fas fa-guitar",
    },
    {
      name: "Gym",
      desc: "I love to workout in the Gym to keep me fit and healthy. It really helps to improve my strength and shape.",
      icon: "fas fa-dumbbell",
    }
  ];

  return (
    <section id="services" className="section bg-light">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">What I Do?</span>
        </p>
        <p className="text-5 fw-300 text-center mb-5 wow fadeInUp">
        I'm currently working as a Senior DevOps Engineer in the SRE team at Xero. 
        I maintain internal production systems that are mission critical for the everyday engineering operations.
        Mainly I look after multiple CI/CD instances including 1000s of their build agents and I take care of multiple artifact repositories.
        </p>
        {/* Heading end*/}
        <div className="row gy-5 mt-5">
          {services.length > 0 &&
            services.map((service, index) => (
              <div className="col-sm-6 col-lg-4 wow fadeInUp" key={index}>
                <div className="featured-box text-center px-md-4">
                  <div className="featured-box-icon text-primary text-13">
                    {" "}
                    <i className={service.icon} />
                  </div>
                  <h3 className="text-6 fw-600 mb-3">{service.name}</h3>
                  <p className="text-muted mb-0">{service.desc} </p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
